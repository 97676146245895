<template>
  <div class="connection-wrap">
    <h3 class="connection-title">联系方式</h3>
    <div class="connection-info" v-html="content"></div>
    <div class="connection-web">
      <img src="@/assets/img/img01.png" alt="">
    </div>
    <!-- <p class="connection-web_line"></p> -->
  </div>
</template>

<script>
import { getContact } from '@/request/index'
import { share } from '@/util'

export default {
  data() {
    return { content: '' }
  },
  mounted() {
    this.initPage()
  },
  methods: {
    async initPage() {
      try {
        const res = await getContact()
        if (res.code === 200) {
          this.content = res.data.content
          share({
            title: res.data.articleTitleShare || '兰普博森•联系方式',
            desc: res.data.summaryShare,
          })
        } else {
          throw new Error('') 
        }
      } catch {
        console.log('数据错误')
      }
    }
  }
}
</script>

<style lang="less">
.connection-wrap {
  width: calc(100% - 320px);
  margin: 0 auto;
}
.connection-subtitle {
  display: none;
}
.connection-web_line {
  display: none;
}
.connection-title {
  font-size: 34px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 52px;
}
.connection-info {
  margin-bottom: 52px;
}
.connection-img {
  width: 100%;
  height: auto;
}
.connection-web {
  display: none;
}
</style>

<style lang="less">
.web {
  .connection-info {
    display: none;
  }
  .connection-web {
    display: inline-block;
    img {
      width: 100%;
      height: auto;
      float: left;
      margin-top: 0.4rem;
    }
  }
  .connection-wrap {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.15rem;
  }
  .connection-title {
    font-size: 0.27rem;
    height: 0.38rem;
    margin-bottom: 0.24rem;
  }
  .connection-subtitle {
    display: block;
    height: 0.22rem;
    font-size: 0.16rem;
    color: #000000;
    font-weight: 500;
    margin-bottom: 0.17rem;
  }
  .connection-info {
    font-size: 0.16rem;
    // line-height: 0.24rem;
    margin-bottom: 0.3rem;
    img, video, p {
      max-width: 3.45rem;
    }
  }
  .connection-img {
    margin-bottom: 0.2rem;
    max-width: 3.45rem;
  }
  .connection-web_line {
    display: block;
    width: 100%;
    height: 1px;
    background: #D8D8D8;
    margin-top: 0.18rem;
  }
}
</style>
