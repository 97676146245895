<template>
  <div class="join-wrap">
    <h3 class="join-title">加入我们</h3>
    <div class="join-content" v-html="content"></div>
    <div class="join-web">
      <img src="@/assets/img/img001.png" alt="">
      <img src="@/assets/img/img002.png" alt="">
      <img src="@/assets/img/img003.png" alt="">
      <img src="@/assets/img/img004.png" alt="">
    </div>
    <!-- <ul class="join-main">
      <li>
        <div class="join-left">
          <img src="@/assets/img/testImg.png" alt="">
          <p>简历以PDF或链接的形式发送至邮箱<br/>info@blow.hk，大小不超过50M</p>
        </div>
        <div class="join-right">
          <h4>创意文案&资深创意文案</h4>
          <p class="join-needs">
            首先你得是社交狂人，旅游达人<br/>
            首先你得是社交狂人，旅游达人<br/>
            首先你得是社交狂人，旅游达人<br/>
            首先你得是社交狂人，旅游达人<br/>
          </p>
          <h6>岗位职责</h6>
          <p class="join-exports">
            1.负责产出有趣、有效的创意策划；<br/>
            2.负责产出有趣、有效的创意策划；<br/>
            3.负责产出有趣、有效的创意策划；<br/>
          </p>
          <h6>我们希望你</h6>
          <p class="join-exports">
            1.负责产出有趣、有效的创意策划负责产出有趣、有效的创意策划负责产出有趣、有效的创意策划负责产出有趣、有效的创意策划；<br/>
            2.负责产出有趣、有效的创意策划；<br/>
            3.负责产出有趣、有效的创意策划；<br/>
          </p>
        </div>
      </li>
      <li>
        <div class="join-left">
          <img src="@/assets/img/testImg.png" alt="">
          <p>简历以PDF或链接的形式发送至邮箱<br/>info@blow.hk，大小不超过50M</p>
        </div>
        <div class="join-right">
          <h4>创意文案&资深创意文案</h4>
          <p class="join-needs">
            首先你得是社交狂人，旅游达人<br/>
            首先你得是社交狂人，旅游达人<br/>
            首先你得是社交狂人，旅游达人<br/>
            首先你得是社交狂人，旅游达人<br/>
          </p>
          <h6>岗位职责</h6>
          <p class="join-exports">
            1.负责产出有趣、有效的创意策划；<br/>
            2.负责产出有趣、有效的创意策划；<br/>
            3.负责产出有趣、有效的创意策划；<br/>
          </p>
          <h6>我们希望你</h6>
          <p class="join-exports">
            1.负责产出有趣、有效的创意策划负责产出有趣、有效的创意策划负责产出有趣、有效的创意策划负责产出有趣、有效的创意策划；<br/>
            2.负责产出有趣、有效的创意策划；<br/>
            3.负责产出有趣、有效的创意策划；<br/>
          </p>
        </div>
      </li>
      <li>
        <div class="join-left">
          <img src="@/assets/img/testImg.png" alt="">
        </div>
        <div class="join-right">
          <h4>创意文案&资深创意文案</h4>
          <p class="join-needs">
            首先你得是社交狂人，旅游达人<br/>
            首先你得是社交狂人，旅游达人<br/>
            首先你得是社交狂人，旅游达人<br/>
            首先你得是社交狂人，旅游达人<br/>
          </p>
          <h6>岗位职责</h6>
          <p class="join-exports">
            1.负责产出有趣、有效的创意策划；<br/>
            2.负责产出有趣、有效的创意策划；<br/>
            3.负责产出有趣、有效的创意策划；<br/>
          </p>
          <h6>我们希望你</h6>
          <p class="join-exports">
            1.负责产出有趣、有效的创意策划负责产出有趣、有效的创意策划负责产出有趣、有效的创意策划负责产出有趣、有效的创意策划；<br/>
            2.负责产出有趣、有效的创意策划；<br/>
            3.负责产出有趣、有效的创意策划；<br/>
          </p>
        </div>
      </li>
      <li>
        <div class="join-left">
          <img src="@/assets/img/testImg.png" alt="">
          <p>简历以PDF或链接的形式发送至邮箱<br/>info@blow.hk，大小不超过50M</p>
        </div>
        <div class="join-right">
          <h4>创意文案&资深创意文案</h4>
          <p class="join-needs">
            首先你得是社交狂人，旅游达人<br/>
            首先你得是社交狂人，旅游达人<br/>
            首先你得是社交狂人，旅游达人<br/>
            首先你得是社交狂人，旅游达人<br/>
          </p>
          <h6>岗位职责</h6>
          <p class="join-exports">
            1.负责产出有趣、有效的创意策划；<br/>
            2.负责产出有趣、有效的创意策划；<br/>
            3.负责产出有趣、有效的创意策划；<br/>
          </p>
          <h6>我们希望你</h6>
          <p class="join-exports">
            1.负责产出有趣、有效的创意策划负责产出有趣、有效的创意策划负责产出有趣、有效的创意策划负责产出有趣、有效的创意策划；<br/>
            2.负责产出有趣、有效的创意策划；<br/>
            3.负责产出有趣、有效的创意策划；<br/>
          </p>
        </div>
      </li>
    </ul> -->
  </div>
</template>

<script>
import { getJoin } from '@/request/index'
import { share } from '@/util'

export default {
  data() {
    return { content: '' }
  },
  mounted() {
    this.initPage()
  },
  methods: {
    async initPage() {
      try {
        const res = await getJoin()
        if (res.code === 200) {
          this.content = res.data.content
          share({
            title: res.data.articleTitleShare || '兰普博森•加入我们',
            desc: res.data.summaryShare,
          })
        } else {
          throw new Error('') 
        }
      } catch {
        console.log('数据错误')
      }
    }
  }
}
</script>

<style lang="less">
.join-wrap {
  width: calc(100% - 320px);
  margin: 0 auto;
}
.join-title {
  font-size: 34px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 52px;
}
.join-head_img {
  width: 100%;
  height: auto;
}
.join-web {
  display: none;
}
.join-main {
  width: 100%;
  li {
    margin-top: 71px;
    width: 100%;
    display: inline-block;
    clear: both;
    &:nth-child(2n) {
      .join-left {
        float: right;
        margin-right: 0;
      }
      .join-right {
        float: left;
        margin-right: 68px;
      }
    }
  }
  .join-left {
    float: left;
    width: 526px;
    margin-right: 68px;
    img {
      width: 100%;
      height: auto;
    }
    p {
      margin-top: 31px;
      font-size: 15px;
      color: #000000;
      font-weight: 400;
    }
  }
  .join-right {
    float: right;
    width: 526px;
    h4 {
      font-size: 29px;
      color: #000000;
      text-align: justify;
      font-weight: 600;
      margin-bottom: 29px;
    }
    .join-needs {
      font-size: 16px;
      color: #000000;
      text-align: justify;
      line-height: 28px;
      font-weight: 400;
    }
    h6 {
      margin-top: 28px;
      font-size: 18px;
      color: #000000;
      text-align: justify;
      line-height: 15px;
      font-weight: 600;
    }
    .join-exports {
      font-size: 18px;
      color: #000000;
      text-align: justify;
      line-height: 28px;
      font-weight: 400;
    }
  }
}
</style>

<style lang="less">
.web {
  .join-wrap {
    width: 100%;
  }
  .join-content {
    display: none;
  }
  .join-web {
    display: inline-block;
    width: 92%;
    margin-left: 4%;
    img {
      width: 100%;
      height: auto;
      float: left;
      margin-top: 0.4rem;
    }
  }
  .join-title {
    font-size: 0.27rem;
    height: 0.38rem;
    box-sizing: border-box;
    padding: 0 0.15rem;
    margin-bottom: 0.23rem;
  }
  .join-left {
    display: none;
  }
  .join-main {
    li {
      margin-top: 0.36rem;
    }
    .join-right {
      width: 100%;
      box-sizing: border-box;
      padding: 0 0.15rem;
      h4 {
        font-size: 0.2rem;
        line-height: 0.26rem;
        margin-bottom: 0.1rem;
      }
      .join-needs {
        font-size: 0.14rem;
        line-height: 0.23rem;
      }
      h6 {
        margin-top: 0.22rem;
        font-size: 0.14rem;
        line-height: 0.23rem;
      }
      .join-exports {
        font-size: 0.14rem;
        line-height: 0.23rem;
      }
    }
  }
  .join-content {
    padding: 0 0.15rem;
    font-size: 0.16rem;
  }
}
</style>
