<template>
  <div class="image-wrap" :style="imgStyle">
    <img class="image-main" :class="isHover ? 'hover' : ''" :src="src" alt="" />
    <p class="image-tips" :style="tipStyle">{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    imgStyle: {
      type: Object,
      required: false
    },
    tipStyle: {
      type: Object,
      required: false,
    },
    isHover: {
      type: Boolean,
      required: false,
      default: true
    },
    text: {
      type: String,
      required: false
    },
    src: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="less">
.image-wrap {
  position: relative;
  min-width: 100px;
  min-height: 114px;
  background: #efefef;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.image-tips {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.55);
  font-weight: 600;
  width: 100%;
  height: 90px;
  line-height: 90px;
  font-size: 21px;
  text-align: left;
  text-indent: 23px;
  overflow: hidden;
  transform: translateY(100%);
  transition: 1s;
  color: #FFFFFF;
  letter-spacing: 2px;
  font-weight: 400;
}
.image-wrap:hover .image-tips {
  transform: translateY(0);
}
.image-main {
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: .3s;
  &.hover:hover {
    transform: scale(1.1);
  }
}
</style>

<style lang="less">
.web {
  .image-wrap {
    min-width: 0.3rem;
    min-height: 0.3rem;
  }
  .image-tips {
    display: none;
  }
}
</style>
