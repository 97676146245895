import { render, staticRenderFns } from "./swiper0.vue?vue&type=template&id=1d325063&scoped=true&"
import script from "./swiper0.vue?vue&type=script&lang=js&"
export * from "./swiper0.vue?vue&type=script&lang=js&"
import style0 from "./swiper0.vue?vue&type=style&index=0&id=1d325063&lang=less&scoped=true&"
import style1 from "./swiper0.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d325063",
  null
  
)

export default component.exports