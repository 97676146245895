<template>
<div class="index-wrap">
  <ul class="success-tab">
    <li v-for="(item, key) in tabList" :key="item" :class="[curTab === key ? 'active' : '']" @click="tabClick(key)">{{ item }}</li>
  </ul>
  <div class="center-wrap">
    <swiper0 :listData="lisData0" v-if="curTab === 0" />
    <swiper1 :listData="lisData1" v-if="curTab === 1" />
    <swiper2 :listData="lisData2" v-if="curTab === 2" />
    <div class="center-line"></div>
    <div class="center-two" v-show="!!pageData0.length && curTab === 0">
      <template v-for="(item, index) in [...pageData0, ...pageData]">
        <template>
          <image-hover :key="index" class="center-three_item" @click.native="junmArtical(item)" :src="item.imgUrlList" :text="item.articleTitle" :imgStyle="{'width': '100%', height: 'auto'}"
            :tipStyle="{'textIndent': ['《', '“', '‘', '【'].includes(item.articleTitle.slice(0, 1)) ? '-0.5em' : ''}"
          />
        </template>
      </template>
    </div>
    <div class="center-two" v-show="!!pageData1.length && curTab === 1">
      <template v-for="(item, index) in [...pageData1, ...pageData]">
        <template>
          <image-hover :key="index" class="center-three_item" @click.native="junmArtical(item)" :src="item.imgUrlList" :text="item.articleTitle" :imgStyle="{'width': '100%', height: 'auto'}"
            :tipStyle="{'textIndent': ['《', '“', '‘', '【'].includes(item.articleTitle.slice(0, 1)) ? '-0.5em' : ''}"
          />
        </template>
      </template>
    </div>
    <div class="center-two" v-show="!!pageData2.length && curTab === 2">
      <template v-for="(item, index) in [...pageData2, ...pageData]">
        <template>
          <image-hover :key="index" class="center-three_item" @click.native="junmArtical(item)" :src="item.imgUrlList" :text="item.articleTitle" :imgStyle="{'width': '100%', height: 'auto'}"
            :tipStyle="{'textIndent': ['《', '“', '‘', '【'].includes(item.articleTitle.slice(0, 1)) ? '-0.5em' : ''}"
          />
        </template>
      </template>
    </div>
    <div class="center-web" v-show="!!pageData0.length && curTab === 0">
      <template v-for="(item, index) in [...pageData0, ...pageData]">
        <template >
          <img :key="index" class="center-webimg" @click="junmArtical(item)" :src="item.imgUrlList" alt="">
          <p :style="{'textIndent': ['《', '“', '‘', '【'].includes(item.articleTitle.slice(0, 1)) ? '-0.5em' : ''}" :key="index">{{ item.articleTitle }}</p>
          <span :key="index">{{ item.articleTitleSub }}</span>
        </template>
      </template>
    </div>
    <div class="center-web" v-show="!!pageData1.length && curTab === 1">
      <template v-for="(item, index) in [...pageData1, ...pageData]">
        <template >
          <img :key="index" class="center-webimg" @click="junmArtical(item)" :src="item.imgUrlList" alt="">
          <p :style="{'textIndent': ['《', '“', '‘', '【'].includes(item.articleTitle.slice(0, 1)) ? '-0.5em' : ''}" :key="index">{{ item.articleTitle }}</p>
          <span :key="index">{{ item.articleTitleSub }}</span>
        </template>
      </template>
    </div>
    <div class="center-web" v-show="!!pageData2.length && curTab === 2">
      <template v-for="(item, index) in [...pageData2, ...pageData]">
        <template >
          <img :key="index" class="center-webimg" @click="junmArtical(item)" :src="item.imgUrlList" alt="">
          <p :style="{'textIndent': ['《', '“', '‘', '【'].includes(item.articleTitle.slice(0, 1)) ? '-0.5em' : ''}" :key="index">{{ item.articleTitle }}</p>
          <span :key="index">{{ item.articleTitleSub }}</span>
        </template>
      </template>
    </div>
  </div>
  <p v-if="loadText" class="index-loading">加载中...</p>
  <p v-else class="index-ending">敬请期待更多案例</p>
</div>
</template>

<script>
import ImageHover from '@/components/ImageHover'
import { getIndexList } from '@/request/index'
import swiper0 from './swiper0.vue'
import swiper1 from './swiper1.vue'
import swiper2 from './swiper2.vue'
import { share } from '@/util'

export default {
  components: {
    ImageHover,
    swiper0,
    swiper1,
    swiper2,
  },
  props: {
    navmenu: {
      type: Array
    }
  },
  data() {
    return {
      lisData: [],
      lisData0: [],
      lisData1: [],
      lisData2: [],
      tabList: [],
      curTab: 0,
      pageData: [],
      pageData0: [],
      pageData1: [],
      pageData2: [],
      pageClientHeight: '',
      scrollContainer: '',
      pageNum: 1,
      loadFlag: true,
      loadText: true,
    }
  },
  mounted() {
    this.initPages('first')
    // 浏览器视口的高度
    function getWindowHeight() {
      let windowHeight = 0;
      if (document.compatMode === "CSS1Compat") {
        windowHeight = document.documentElement.clientHeight;
      } else {
        windowHeight = document.body.clientHeight;
      }
      return windowHeight;
    }
    this.pageClientHeight = getWindowHeight();
    this.scrollContainer = document.querySelector('.page-conter');
    // 监听滚动事件
    window.addEventListener('scroll', this.scrollPage)
    share()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollPage)
  },
  methods: {
    scrollPage() {
      if (!this.pageClientHeight || !this.scrollContainer) return
      function getScrollTop() {
        let scrollTop = 0;
        let bodyScrollTop = 0;
        let documentScrollTop = 0;
        if (document.body) {
          bodyScrollTop = document.body.scrollTop;
        }
        if (document.documentElement) {
          documentScrollTop = document.documentElement.scrollTop;
        }
        scrollTop =
          bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
        return scrollTop;
      }
      // 当元素的可视高度+滚入的距离>=元素真实高度-触底距离时,触发加载更多
      if ((this.pageClientHeight + getScrollTop()) >= this.scrollContainer.scrollHeight - 50) {
        if (this.loadFlag === true && this.pageData0.length) {
          this.loadFlag = false
          this.pageNum += 1
          this.loadText = true
          this.initPages()
        }
      }
    },
    jumpSuccess() {
      this.$router.push({ path: 'successNews', query: { tab: this.curTab }})
    },
    junmArtical(row) {
      this.$router.push({ path: 'detail' , query: {id: row.articleId}})
    },
    async initSwiper(tabMap) {
      try {
        const resSwiper = await getIndexList({
          pageNum: 1,
          pageSize: 8,
          channelId: tabMap[this.curTab],
          isBanner: '1'
        })
        if (resSwiper.code === 200) {
          if (this.curTab === 0 && this.lisData0.length === 0) {
            this.lisData0 = resSwiper.data.rows
          }
          if (this.curTab === 1 && this.lisData1.length === 0) {
            this.lisData1 = resSwiper.data.rows
          }
          if (this.curTab === 2 && this.lisData2.length === 0) {
            this.lisData2 = resSwiper.data.rows
          }
        } else {
          throw new Error('') 
        }
      } catch (error) {
        console.log('swiper数据错误:', error)
      }
    },
    tabClick(key) {
      this.curTab = key
      this.pageNum = 1
      this.loadFlag = true
      this.initPages()
    },
    async renderOther(tabMap) {
      const pageSize = 13;
      try {
        const res = await getIndexList({
          pageNum: 1, pageSize,
          channelId: tabMap[1]
        })
        if (res.code === 200) {
          if (res.data) {
            this.pageData1 = res.data.rows
          }
        } else {
          throw new Error('') 
        }
      } catch (err){
        console.log('数据错误:', err)
      }
      try {
        const res = await getIndexList({
          pageNum: 1, pageSize,
          channelId: tabMap[2]
        })
        if (res.code === 200) {
          if (res.data) {
            this.pageData2 = res.data.rows
          }
        } else {
          throw new Error('') 
        }
      } catch (err){
        console.log('数据错误:', err)
      }
    },
    async initPages(isFirst) {
      const successData = this.navmenu.filter(item => item.link === 'successNews')[0]
      const tabMap = {}
      if (successData) {
        this.tabList = successData.children.map((item, index) => {
          tabMap[index] = item.id
          return item.name
        })
      }
      this.initSwiper(tabMap)
      if (isFirst === 'first') {
        this.renderOther(tabMap)
      }
      try {
        const pageSize = 13;
        const res = await getIndexList({
          pageNum: this.pageNum, pageSize,
          channelId: tabMap[this.curTab]
        })
        if (res.code === 200) {
          if (res.data) {
            if (this.pageNum === 1) {
              if (isFirst === 'first') {
                this.pageData0 = res.data.rows
              }
              this.pageData = []
            } else {
              this.pageData.push(...res.data.rows)
            }
            if (res.data.rows.length < pageSize) {
              this.loadFlag = false
              this.loadText = false
            } else {
              this.loadFlag = true
              this.loadText = true
            }
          }
          this.lisData = res.data.rows
        } else {
          throw new Error('') 
        }
      } catch (err){
        console.log('数据错误:', err)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.index-wrap {
  width: 100%;
  /deep/ .swiper-container {
    width: 100%;
    height: auto;
    .swiper-pagination {
      bottom: 30px;
    }
    .swiper-pagination-bullet-active {
      background: #D80716;
    }
    .center-web {
      display: none;
    }
  }
}
.center-line {
  background: #D8D8D8;
  border: 0.75px solid rgba(220,220,220,1);
  margin: 33px auto;
  width: calc(100% - 320px);
}
.success-tab {
  position: absolute;
  top: 28px;
  left: calc(50% - 280px);
  width: 560px;
  height: 60px;
  text-align: center;
  li {
    font-size: 17px;
    line-height: 46px;
    color: #000000;
    font-weight: 400;
    display: inline-block;
    margin: 0 30px;
    margin-top: 11px;
    cursor: pointer;
    &.active {
      border-bottom: 1px solid #000000;
    }
  }
}
.center-wrap {
  width: 100%;
}
.center-web {
  display: none;
}
.center-two {
  width: calc(100% - 300px);
  margin: 0 auto;
}
.center-three, .center-two, .center-two_left, .center-two_right {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin-top: 26px;
}
.margin-top32 {
  margin-top: 32px;
}
.center-three_item {
  flex: 1;
  min-width: calc(50% - 20px);
  max-width: calc(50% - 20px);
  box-sizing: border-box;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 26px;
}
.center-three_item:last-child {
  margin-right: 0;
}
.center-two_left .center-three_item:first-child {
  min-width: 734px;
}
.center-two_right .center-three_item:last-child {
  min-width: 734px;
}
.center-more {
  display: block;
  margin: 0 auto;
  margin-top: 109px;
  width: 214px;
  height: 39px;
  line-height: 39px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #D8D8D8;
  cursor: pointer;
  transition: 0.6s;
  &:hover {
    border: 1px solid #000000;
    font-weight: 500;
  }
}
.index-loading {
  font-size: 14px;
  color: #000000;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 400;
  width: 100%;
  text-align: center;
  padding-top: 50px;
}
.index-ending {
  font-size: 14px;
  color: #000000;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 400;
  width: calc(100% - 320px);
  margin: 0 auto;
  text-align: center;
  padding-top: 50px;
  border-top: 0.75px solid #D8D8D8;
}
</style>

<style lang="less">
.web {
  .center-three, .center-two, .center-two_left, .center-two_right, .margin-top32 {
    display: none;
  }
  .center-line {
    margin-top: 0.16rem;
    margin-bottom: 0.38rem;
    width: 3.45rem;
    box-sizing: border-box;
  }
  .index-loading {
    padding-top: 0.2rem;
  }
  .index-ending {
    padding-top: 0.16rem;
    width: 3.4rem;
  }
  .index-wrap {
    margin-top: 0.99rem;
    .success-tab {
      position: absolute;
      text-align: center;
      top: 0.76rem;
      width: 100%;
      height: 0.6rem;
      left: 0;
      margin-top: 0;
      li {
        font-size: 0.14rem;
        line-height: 0.34rem;
      }
    }
    .center-web {
      box-sizing: border-box;
      display: block;
      padding: 0 0.15rem;
      width: 100%;
      .center-top_title {
        display: block;
        line-height: 0.23rem;
        font-size: 0.17rem;
        color: #000000;
        margin-bottom: 0.27rem;
        margin-top: 0.1rem;
      }
      .center-webimg {
        width: 100%;
        height: auto;
        min-height: 1rem;
        background: #efefef;
      }
      p {
        font-size: 0.18rem;
        color: #000000;
        margin-bottom: 0.02rem;
        margin-top: 0.12rem;
        font-weight: 600;
      }
      span {
        display: inline-block;
        font-size: 0.16rem;
        color: #000000;
        font-weight: 400;
        margin-bottom: 0.32rem;
      }
    }
    .center-more {
      margin-top: 1.86rem;
      width: 1.6rem;
      height: 0.34rem;
      line-height: 0.34rem;
      font-size: 0.14rem;
    }
    .center-three_imgauto {
      height: auto !important;
    }
  }
  .index-wrap .swiper-container {
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: 2.1rem !important;
    }
    .swiper-pagination-bullet-active {
      background: #D80716;
    }
    .swiper-pagination {
      bottom: 0.12rem;;
    }
  }
}
</style>
