<template>
  <div class="news-wrap">
    <h3 class="news-title">新闻中心</h3>
    <ul class="news-list">
      <li @click="toDetail(item)" v-for="item in lisData" :key="item.articleId">
        <div class="news-list_left">
          <h6>{{ item.articleTitle }}</h6>
          <p>{{ item.summary }}</p>
        </div>
        <div class="new-list_right">
          <img :src="item.imgUrlList" alt="">
        </div>
      </li>
    </ul>
    <p v-if="loadText" class="news-loading">加载中...</p>
    <p v-else class="news-ending">敬请期待更多新闻</p>
  </div>
</template>

<script>
import { getNewsList } from '@/request/index'
import { share } from '@/util'

export default {
  data() {
    return {
      pageCount: 20,
      showPaginate: false,
      prevText: '',
      nextText: '',
      lisData: [],
      pageSize: 10,
      pageClientHeight: '',
      scrollContainer: '',
      pageNum: 1,
      loadFlag: true,
      loadText: true,
    }
  },
  mounted() {
    this.initPage()
    // 浏览器视口的高度
    function getWindowHeight() {
      let windowHeight = 0;
      if (document.compatMode === "CSS1Compat") {
        windowHeight = document.documentElement.clientHeight;
      } else {
        windowHeight = document.body.clientHeight;
      }
      return windowHeight;
    }
    this.pageClientHeight = getWindowHeight();
    this.scrollContainer = document.querySelector('.page-conter');
    // 监听滚动事件
    window.addEventListener('scroll', this.scrollPage)
    share({
      title: '兰普博森•新闻中心'
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollPage)
  },
  methods: {
    scrollPage() {
      if (!this.pageClientHeight || !this.scrollContainer) return
      function getScrollTop() {
        let scrollTop = 0;
        let bodyScrollTop = 0;
        let documentScrollTop = 0;
        if (document.body) {
          bodyScrollTop = document.body.scrollTop;
        }
        if (document.documentElement) {
          documentScrollTop = document.documentElement.scrollTop;
        }
        scrollTop =
          bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
        return scrollTop;
      }
      // 当元素的可视高度+滚入的距离>=元素真实高度-触底距离时,触发加载更多
      if ((this.pageClientHeight + getScrollTop()) >= this.scrollContainer.scrollHeight - 50) {
        if (this.loadFlag === true && this.lisData.length) {
          this.loadFlag = false
          this.pageNum += 1
          this.loadText = true
          this.initPage()
        }
      }
    },
    async initPage() {
      try {
        const res = await getNewsList({ pageNum: this.pageNum, pageSize: this.pageSize })
        if (res.code === 200) {
          if (this.pageNum === 1) {
            this.lisData = []
          }
          this.lisData.push(...res.data.rows)
          if (res.data.rows.length < this.pageSize) {
            this.loadFlag = false
            this.loadText = false
          } else {
            this.loadFlag = true
            this.loadText = true
          }
        } else {
          throw new Error('') 
        }
      } catch {
        console.log('数据错误')
      }
    },
    toDetail(row) {
      this.$router.push({ path: 'detail' , query: {id: row.articleId, isNews: false}})
    }
  }
}
</script>

<style lang="less">
.news-wrap {
  width: calc(100% - 320px);
  margin: 0 auto;
}
.news-title {
  font-size: 33px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 41px;
}
.news-list {
  width: 100%;
  li {
    width: 100%;
    padding-top: 41px;
    padding-bottom: 41px;
    display: inline-block;
    cursor: pointer;
    border-top: 1px solid #D8D8D8;
    .news-list_left {
      width: 736px;
      float: left;
    }
    h6 {
      font-size: 23px;
      color: #000000;
      text-align: justify;
      font-weight: 600;
      margin-bottom: 14px;
    }
    p {
      font-size: 14px;
      color: #000000;
      text-align: justify;
      line-height: 22px;
      font-weight: 400;
    }
    .new-list_right {
      width: 352px;
      height: 200px;
      float: right;
      background: #f4f4f4;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: .3s;
      }
    }
    &:hover {
      .new-list_right img {
        transform: scale(1.1);
      }
    }
  }
}
.news-pagination {
  display: inline-block;
  li {
    display: inline-block;
    float: left;
    margin: 0 15px;
    &.active {
      border-bottom: 1px solid #000000;
    }
  }
}
.pagination-box {
  width: 100%;
  margin-top: 193px;
  text-align: center;
}
.news-loading {
  font-size: 14px;
  color: #000000;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 400;
  width: 100%;
  text-align: center;
  padding-top: 50px;
}
.news-ending {
  font-size: 14px;
  color: #000000;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 400;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 50px;
  border-top: 0.75px solid #D8D8D8;
}
</style>

<style lang="less">
.web {
  .news-wrap {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.15rem;
  }
  .news-loading {
    padding-top: 0.2rem;
  }
  .news-ending {
    padding-top: 0.16rem;
    width: 3.4rem;
  }
  .news-title {
    font-size: 0.27rem;
    margin-bottom: 0.23rem;
  }
  .news-list {
    li {
      padding-top: 0.36rem;
      h6 {
        font-size: 0.19rem;
        line-height: 0.23rem;
      }
      p {
        font-size: 00.14rem;
        line-height: 0.23rem;
        margin-bottom: 0.1rem;
      }
      .news-list_left {
        width: 100%;
      }
      .new-list_right {
        width: 100%;
      }
    }
  }
  .news-pagination {
    li {
      margin: 0 0.12rem;
      float: none;
      font-size: 0.14rem;
    }
  }
  .pagination-box {
    margin-top: 1.86rem;
  }
}
</style>
