<template>
  <div class="detail-wrap">
    <img v-if="content.articleType !== '4'" class="detail-img" :src="content.contentBannerUrlList" alt="">
    <div v-else class="video-wrap">
      <video ref="videoRef" :src="content.videoUrl" :poster="content.contentBannerUrlList" class="detail-img1" preload="auto" controls="controls" controlslist="nodownload"></video>
      <div class="video-btn" @click="clickBtn" v-if="videoBtnStatue"></div>
    </div>
    <h3 v-if="content.articleTitle" class="detail-title" :style="{'text-indent': ['《', '“', '‘', '【'].includes(content.articleTitle.slice(0, 1)) ? '-0.5em' : ''}">{{ content.articleTitle }}</h3>
    <h3 class="detail-title_sub">{{ content.articleTitleSub }}</h3>
    <h3 class="detail-title_sort">{{ content.articleTitleShort }}</h3>
    <div class="detail-main">
      <div class="detail-section" v-html="content.content"></div>
      <p class="detail-line"></p>
    </div>
  </div>
</template>

<script>
import { getDetails } from '@/request/index'
import { share } from '@/util'

export default {
  data() {
    return {
      content: {},
      videoBtnStatue: true,
      newsStatus: true
    }
  },
  async mounted() {
    if (this.$route.query.isNews === 'false') {
      this.newsStatus = false
    }
    await this.initPage(this.$route.query.id)
    window.document.documentElement.scrollTop = 0
    // 监听滚动事件
    window.addEventListener('scroll', this.scrollPage)
    if (this.content.articleType !== '4') {
      document.querySelector('.top-logo').style.transform = 'scale(1.4)'
    } else {
      document.querySelector('.top-logo').style.transform = 'scale(1)'
    }
  },
  methods: {
    scrollPage() {
      function getScrollTop() {
        let scrollTop = 0;
        let bodyScrollTop = 0;
        let documentScrollTop = 0;
        if (document.body) {
          bodyScrollTop = document.body.scrollTop;
        }
        if (document.documentElement) {
          documentScrollTop = document.documentElement.scrollTop;
        }
        scrollTop =
          bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
        return scrollTop;
      }
      // 当元素的可视高度+滚入的距离>=元素真实高度-触底距离时,触发加载更多
      const size = window.fontSize || 100
      const sizeHeight = window.platform === 'pc' ? 600 : size * 2.57
      function getPercent() {
        if (getScrollTop() <= sizeHeight) {
          return getScrollTop() / sizeHeight
        }
        return 1
      }
      if (this.content.articleType !== '4') {
        document.querySelector('.top-logo').style.transform = `scale(${1.4 - 0.4 * getPercent()})`
      }
    },
    clickBtn() {
      this.$refs.videoRef.play()
      this.videoBtnStatue = false
    },
    async changeArtical(id) {
      await this.initPage(id)
      window.document.documentElement.scrollTop = 0
    },
    async initPage(id) {
      try {
        const res = await getDetails(id)
        if (res.code === 200) {
          this.content = res.data
          share({
            title: res.data.articleTitleShare || res.data.articleTitle,
            desc: res.data.summaryShare || res.data.articleTitleSub,
          })
          if (this.content.articleType !== '4') {
            document.getElementById('app').classList = 'artical-page artical'
          } else {
            document.getElementById('app').classList = 'artical'
          }
        } else {
          throw new Error('') 
        }
      } catch {
        console.log('数据错误')
      }
    }
  },
  beforeDestroy() {
    document.getElementById('app').classList = ''
    window.removeEventListener('scroll', this.scrollPage)
    document.querySelector('.top-logo').style.transform = 'scale(1)'
  },
}
</script>

<style lang="less">
.artical-page .top-wrap {
  box-shadow: none;
  margin-bottom: 0;
  .top-logo {
    position: absolute;
    transform-origin: left;
    transform: scale(1.4);
    width: 86px;
    height: 86px;
  }
}
.artical {
  .top-wrap {
    position: fixed;
    z-index: 99999;
  }
  .detail-wrap {
    margin-top: 132px;
  }
  &.artical-page .detail-wrap {
    margin-top: 86px;
  }
}
.web .artical-page .top-wrap {
  box-shadow: none;
  margin-bottom: 0;
  .top-logo {
    position: absolute;
    transform-origin: left;
    transform: scale(1.4);
    width: 0.56rem;
    height: 0.56rem;
  }
}
.web .artical {
  .detail-wrap {
    margin-top: 1rem;
  }
  &.artical-page .detail-wrap {
    margin-top: 0.56rem;
  }
}
.detail-wrap {
  width: 100%;
}
video::-internal-media-controls-download-button {
    display:none;
}
video::-webkit-media-controls-enclosure {
    overflow:hidden;
}
video::-webkit-media-controls-panel {
    width: calc(100% + 30px); 
}
.detail-title {
  font-size: 35px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 57px;
  width: calc(100% - 320px);
  margin: 0 auto;
  letter-spacing: 3px;
  font-family: 'SourceHanSansCN-Normal', 'Avenir-Medium';
}
.detail-title_sub {
  font-size: 28px;
  color: #000000;
  letter-spacing: 1px;
  line-height: 45px;
  font-weight: 400;
  width: calc(100% - 320px);
  margin: 0 auto;
  margin-bottom: 15px;
  letter-spacing: 1px;
  font-family: 'SourceHanSansCN-Normal', 'Avenir-Medium';
}
.detail-title_sort {
  font-size: 12px;
  color: #000000;
  letter-spacing: 1.71px;
  font-weight: 400;
  width: calc(100% - 320px);
  margin: 0 auto;
  padding-bottom: 17px;
  border-bottom: 1px solid rgba(220,220,220,1);
  letter-spacing: 2px;
  font-family: 'SourceHanSansCN-Normal', 'Avenir-Medium';
}
.detail-main {
  width: calc(100% - 600px);
  margin: 0 auto;
  font-size: 14px;
  color: #000000;
  text-align: justify;
  line-height: 24px;
  font-weight: 400;
  margin-top: 48px;
  img {
    display: flex;
  }
  img, video {
    width: calc(100% + 280px);
    margin-left: -140px;
    max-width: 10000px !important;
  }
}
.video-wrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
  .video-btn {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 81;
    width: 100%;
    height: 100%;
    background: url('~@/assets/img/vbtn.png') no-repeat;
    background-size: 100px 100px;
    background-position: center center;
    text-align: center;
    overflow: hidden;
    color: #333;
  }
}
.detail-img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.detail-img1 {
  width: 100%;
  height: 700px;
  background: #000000;
  -webkit-filter: grayscale(20%);
}
.detail-webimg {
  display: block;
}
.detail-line {
  display: none;
}
.detail-section {
  width: 100%;
  // margin: 0 auto;
  // p {
  //   width: 100%;
  //   margin-bottom: 71px;
  // }
}
</style>

<style lang="less">
.web {
  .detail-title {
    box-sizing: border-box;
    padding: 0 0.15rem;
    font-size: 0.3rem;
    margin-bottom: 0;
    width: 100%;
    letter-spacing: 0.03rem;
    font-family: 'SourceHanSansCN-Normal', 'Avenir-Medium';
  }
  .detail-title_sub {
    font-size: 0.28rem;
    line-height: 0.28rem;
    width: calc(100% - 0.3rem);
    margin: 0 0.15rem;
    margin-bottom: 0.12rem;
    letter-spacing: 0.01rem;
    font-family: 'SourceHanSansCN-Normal', 'Avenir-Medium';
  }
  .detail-title_sort {
    font-size: 0.12rem;
    width: calc(100% - 0.3rem);
    margin: 0 0.15rem;
    padding-bottom: 0.21rem;
    font-weight: 400;
    letter-spacing: 0.02rem;
    font-family: 'SourceHanSansCN-Normal', 'Avenir-Medium';
  }
  .detail-main {
    width: 100%;
    margin-top: 0.22rem;
    font-size: 0.14rem;
    line-height: 0.24rem;
    img, video {
      width: calc(100% + 0.3rem);
      margin-left: -0.15rem;
    }
  }
  .detail-img {
    margin-bottom: 0.2rem;
    height: 2.57rem;
    object-fit: cover;
  }
  .detail-img1 {
    width: 100%;
    height: auto;
  }
  .video-wrap {
    position: relative;
    .video-btn {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 81;
      width: 100%;
      height: 100%;
      background: url('~@/assets/img/vbtn.png') no-repeat;
      background-size: 0.5rem 0.5rem;
      background-position: center center;
      text-align: center;
      overflow: hidden;
      color: #333;
    }
  }
  .detail-webimg {
    display: none;
  }
  .detail-line {
    display: block;
    width: 3.45rem;
    margin-left: 0.15rem;
    height: 1px;
    background: #D8D8D8;
    margin-top: 0.2rem;
  }
  .detail-section {
    box-sizing: border-box;
    padding: 0 0.15rem;
    width: 100%;
    // p {
    //   font-size: 0.14rem;
    //   line-height: 0.24rem;
    //   margin-bottom: 0.31rem;
    // }
  }
}
</style>
