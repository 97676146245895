<template>
  <div class="success-wrap">
    <div class="succcess-top">
      <h3 class="success-title">成功案例</h3>
      <ul class="success-tab">
        <li v-for="(item, key) in tabList" :key="key" :class="[curTab === key ? 'active' : '']" @click="tabClick(key)">{{ item }}</li>
      </ul>
    </div>
    <ul class="success-main">
      <li v-for="item in lisData" :key="item.articleId" @click="toDetail(item)">
        <div class="success-main_img">
          <img :src="item.imgUrlList" alt="">
        </div>
        <h6 :style="{'textIndent': ['《', '“', '‘', '【'].includes(item.articleTitle.slice(0, 1)) ? '-0.5em' : ''}">{{ item.articleTitle }}</h6>
        <p>{{ item.articleTitleSub }}</p>
      </li>
    </ul>
    <p v-if="loadText" class="success-loading">加载中...</p>
    <p v-else class="success-ending">敬请期待更多案例</p>
  </div>
</template>

<script>
import { getSuccessList } from '@/request/index'
import { share } from '@/util'

export default {
  data() {
    return { 
      tabList: [],
      curTab: 0,
      pageCount: 20,
      showPaginate: false,
      prevText: '',
      nextText: '',
      lisData: [],
      pageSize: 10,
      pageClientHeight: '',
      scrollContainer: '',
      pageNum: 1,
      loadFlag: true,
      loadText: true,
     }
  },
  props: {
    navmenu: {
      type: Array
    }
  },
  mounted() {
    this.curTab = Number(this.$route.query.tab)
    this.initPage()
    // 浏览器视口的高度
    function getWindowHeight() {
      let windowHeight = 0;
      if (document.compatMode === "CSS1Compat") {
        windowHeight = document.documentElement.clientHeight;
      } else {
        windowHeight = document.body.clientHeight;
      }
      return windowHeight;
    }
    this.pageClientHeight = getWindowHeight();
    this.scrollContainer = document.querySelector('.page-conter');
    // 监听滚动事件
    window.addEventListener('scroll', this.scrollPage)
    share({
      title: '兰普博森•成功案例'
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollPage)
  },
  watch: {
    '$route.query.tab'(val) {
      this.curTab = Number(val)
    }
  },
  methods: {
    scrollPage() {
      if (!this.pageClientHeight || !this.scrollContainer) return
      function getScrollTop() {
        let scrollTop = 0;
        let bodyScrollTop = 0;
        let documentScrollTop = 0;
        if (document.body) {
          bodyScrollTop = document.body.scrollTop;
        }
        if (document.documentElement) {
          documentScrollTop = document.documentElement.scrollTop;
        }
        scrollTop =
          bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
        return scrollTop;
      }
      // 当元素的可视高度+滚入的距离>=元素真实高度-触底距离时,触发加载更多
      if ((this.pageClientHeight + getScrollTop()) >= this.scrollContainer.scrollHeight - 50) {
        if (this.loadFlag === true && this.lisData.length) {
          this.loadFlag = false
          this.pageNum += 1
          this.loadText = true
          this.initPage()
        }
      }
    },
    async initPage() {
      const successData = this.navmenu.filter(item => item.link === 'successNews')[0]
      const tabMap = {}
      if (successData) {
        this.tabList = successData.children.map((item, index) => {
          tabMap[index] = item.id
          return item.name
        })
      }
      try {
        const res = await getSuccessList({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          channelId: tabMap[this.curTab]
        })
        if (res.code === 200) {
          if (this.pageNum === 1) {
            this.lisData = []
          }
          this.lisData.push(...res.data.rows)
          if (res.data.rows.length < this.pageSize) {
            this.loadFlag = false
            this.loadText = false
          } else {
            this.loadFlag = true
            this.loadText = true
          }
        } else {
          throw new Error('') 
        }
      } catch {
        console.log('数据错误')
      }
    },
    tabClick(key) {
      this.curTab = key
      this.pageNum = 1
      this.loadFlag = true
      this.initPage()
    },
    toDetail(row) {
      this.$router.push({ path: 'detail' , query: {id: row.articleId}})
    }
  }
}
</script>

<style lang="less">
.success-wrap {
  width: calc(100% - 320px);
  margin: 0 auto;
}
.succcess-top {
  position: relative;
  width: 100%;
  margin-bottom: 52px;
  height: 47px;
  border-bottom: 0.75px solid rgba(220,220,220,1);
}
.success-title {
  font-size: 34px;
  line-height: 47px;
  color: #000000;
  font-weight: 600;
}
.success-tab {
  position: absolute;
  bottom: -2px;
  left: calc(50% - 280px);
  width: 560px;
  height: 100%;
  text-align: center;
  li {
    font-size: 17px;
    line-height: 34px;
    color: #000000;
    font-weight: 400;
    display: inline-block;
    margin: 0 30px;
    margin-top: 11px;
    cursor: pointer;
    &.active {
      border-bottom: 1px solid #000000;
    }
  }
}
.success-main {
  width: 100%;
  display: inline-block;
  li {
    width: 543px;
    float: left;
    margin-bottom: 62px;
    cursor: pointer;
    &:nth-child(2n + 1) {
      margin-right: 34px;
    }
    .success-main_img {
      width: 100%;
      height: 309px;
      overflow: hidden;
      background: #efefef;
      img {
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition: .3s;
        object-fit: cover;
      }
    }
    h6 {
      font-size: 21px;
      color: #000000;
      text-align: justify;
      font-weight: 600;
      margin-top: 19px;
      margin-bottom: 14px;
    }
    p {
      font-size: 13px;
      color: #000000;
      text-align: justify;
      line-height: 20px;
      font-weight: 400;
    }
    &:hover {
      .success-main_img img {
        transform: scale(1.1);
      }
    }
  }
}
.success-pagination {
  display: inline-block;
  li {
    display: inline-block;
    float: left;
    margin: 0 15px;
    font-size: 17px;
    &.active {
      border-bottom: 2px solid #000000;
    }
  }
}
.pagination-box {
  width: 100%;
  margin-top: 158px;
  text-align: center;
}
.success-loading {
  font-size: 14px;
  color: #000000;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 400;
  width: 100%;
  text-align: center;
  padding-top: 50px;
}
.success-ending {
  font-size: 14px;
  color: #000000;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 400;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 50px;
  border-top: 0.75px solid #D8D8D8;
}
</style>

<style lang="less">
.web {
  .success-wrap {
    width: 100%;
  }
  .success-loading {
    padding-top: 0.2rem;
  }
  .success-ending {
    padding-top: 0.16rem;
    width: 3.4rem;
  }
  .success-title {
    font-size: 0.27rem;
    height: 0.38rem;
    box-sizing: border-box;
    padding: 0 0.15rem;
  }
  .succcess-top {
    height: auto;
    margin-bottom: 0.36rem;
    margin-left: 0.15rem;
    width: 3.4rem;
  }
  .success-tab {
    box-sizing: border-box;
    padding: 0 0.15rem;
    position: relative;
    width: 100%;
    left: 0;
    text-align: left;
    margin-top: 0.23rem;
    text-align: center;
    li {
      margin: 0 0.2rem;
    }
  }
  .success-main {
    box-sizing: border-box;
    padding: 0 0.15rem;
    li {
      width: 100%;
      margin-bottom: 0.37rem;
      .success-main_img {
        height: auto;
      }
      img {
        width: 100%;
        float: left;
      }
      h6 {
        font-size: 0.22rem;
        line-height: 0.32rem;
        margin-top: 0.07rem;
        margin-bottom: 0;
      }
      p {
        font-size: 0.13rem;
        line-height: 0.23rem;
      }
    }
  }
  .success-pagination {
    li {
      margin: 0 0.12rem;
      float: none;
      font-size: 0.14rem;
    }
  }
  .pagination-box {
    margin-top: 1.86rem;
  }
}
</style>
