<template>
  <swiper class="swiper0" ref="mySwiper" :options="swiperOptions" v-if="listData.length">
    <swiper-slide v-for="item in listData" :key="item.articleId">
      <ImageHover @mouseenter.native="enterFun" @mouseleave.native="leaveFun" @click.native="junmArtical(item)" class="other-slide" :src="item.imgUrlList" :text="item.articleTitle"
        :style="{width: '100%'}"
        :tipStyle="{'textIndent': ['《', '“', '‘', '【'].includes(item.articleTitle.slice(0, 1)) ? 'calc(160px - 0.5em)' : '160px'}"
        :isHover="false"
      />
      <div class="center-web">
        <p class="center-top_title">{{ item.articleTitle }}</p>
      </div>
    </swiper-slide>
    <div class="swiper-pagination swiper-pagination0" ref="pagination" slot="pagination"></div>
    <div class="swiper-button-prev" slot="button-prev"></div>
		<div class="swiper-button-next" slot="button-next"></div>
  </swiper>
</template>

<script>
import ImageHover from '@/components/ImageHover'

export default {
  props: {
    listData: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ImageHover
  },
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          /* 触摸滑动后是否继续轮播 */
          disableOnInteraction: false
        },
        loop: true,
        pagination: {
          el: '.swiper-pagination0',
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  watch: {
    listData: {
      handler(val) {
        if (val[0].articleId) {
          console.log('listData:', this.listData)
        }
      },
      deep: true
    }
  },
  mounted() {
  },
  beforeDestroy() {
    this.swiper.destroy()
  },
  methods: {
    enterFun() {
      if (document.documentElement.className === 'web') {
        return
      }
      this.swiper.autoplay.stop()
    },
    leaveFun() {
      if (document.documentElement.className === 'web') {
        return
      }
      this.swiper.autoplay.start()
    },
    junmArtical(row) {
      this.$router.push({ path: 'detail' , query: {id: row.articleId}})
    },
  },
}
</script>
<style lang="less" scoped>
.swiper-button-prev {
  color: #ffffff;
  left: 160px;
  right: auto;
  opacity: 0;
  transition: 1s;
  width: 50px;
  justify-content: flex-start;
  &:before {
    content: "";
    width: 29px;
    height: 2px;
    background: #ffffff;
    position: absolute;
    left: 0;
  }
}
.swiper-button-next {
  color: #ffffff;
  left: auto;
  right: 160px;
  opacity: 0;
  transition: 1s;
  width: 50px;
  justify-content: flex-end;
  &:before {
    content: "";
    width: 29px;
    height: 2px;
    background: #ffffff;
    position: absolute;
    right: 0;
  }
}
.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 22px;
}
.swiper0 {
  &:hover {
    .swiper-button-prev {
      opacity: 1;
    }
    .swiper-button-next {
      opacity: 1;
    }
  }
}
</style>

<style lang="less">
.web {
  .swiper-button-prev, .swiper-button-next {
    display: none;
  }
}
</style>
