export function share({
  title,
  desc,
} = {
  title: '兰普博森',
  desc: '赋能中国品牌时代价值'
}) {
  if (!title) {
    title = '兰普博森'
  }
  if (!desc) {
    desc = '赋能中国品牌时代价值'
  }
  console.log('info:', title, desc)
  window.wx.ready(() => {
    window.wx.updateAppMessageShareData({ 
      title,
      desc,
      link: window.location.href.replace('/#/', '/?#/'),
      imgUrl: 'https://lpbs.oss-cn-beijing.aliyuncs.com/file/image/logo.png',
      success: function () {
        console.log('成功')
      }
    })
    window.wx.updateTimelineShareData({ 
      title, // 分享标题
      link: window.location.href.replace('/#/', '/?#/'),
      imgUrl: 'https://lpbs.oss-cn-beijing.aliyuncs.com/file/image/logo.png',
      success: function () {
        console.log('成功')
      }
    })
  });
}