<template>
  <div class="about-wrap">
    <h3 class="about-title">全球视野 中国智慧</h3>
    <div class="about-main" v-html="content"></div>
    <div class="about-web">
      <img src="@/assets/img/webimg01.png" alt="">
      <img src="@/assets/img/webimg02.png" alt="">
      <img src="@/assets/img/webimg03.png" alt="">
      <img src="@/assets/img/webimg04.png" alt="">
    </div>
  </div>
</template>

<script>
import { getAbout } from '@/request/index'
import { share } from '@/util'

export default {
  data() {
    return { content: {} }
  },
  mounted() {
    this.initPage()
  },
  methods: {
    async initPage() {
      try {
        const res = await getAbout()
        if (res.code === 200) {
          this.content = res.data.content
          share({
            title: res.data.articleTitleShare || '兰普博森•关于我们',
            desc: res.data.summaryShare,
          })
        } else {
          throw new Error('') 
        }
      } catch {
        console.log('数据错误')
      }
    }
  }
}
</script>

<style lang="less">
.about-wrap {
  width: calc(100% - 320px);
  margin: 0 auto;
}
.about-title {
  font-size: 34px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 52px;
}
.about-head_img {
  width: 100%;
  height: auto;
}
.about-title_sub {
  font-size: 34px;
  color: #000000;
  font-weight: 600;
  margin-top: 48px;
  margin-bottom: 22px;
}
.about-main {
  width: 100%;
  font-size: 18px;
  color: #000000;
  text-align: justify;
  line-height: 28px;
  font-weight: 400;
  padding-bottom: 78px;
}
.about-friend {
  width: 100%;
  display: inline-block;
  h4 {
    margin-top: 75px;
    margin-bottom: 38px;
    font-size: 34px;
    color: #000000;
    font-weight: 600;
  }
  ul {
    width: 100%;
  }
  li {
    width: 139px;
    height: 139px;
    float: left;
    box-sizing: border-box;
    border: 1px solid #D8D8D8;
    display: flex;
    justify-self: center;
    align-items: center;
    img {
      width: 80%;
      height: auto;
      max-height: 90%;
      margin: 0 auto;
    }
  }
}
.about-web {
  display: none;
}
</style>

<style lang="less">
.web {
  .about-wrap {
    width: 100%;
  }
  .about-web {
    display: inline-block;
    img {
      width: 100%;
      height: auto;
      float: left;
    }
  }
  .about-title {
    font-size: 0.27rem;
    height: 0.38rem;
    padding-left: 0.15rem;
    margin-bottom: 0.23rem;
  }
  .about-title_sub {
    margin-top: 0.29rem;
    margin-bottom: 0.13rem;
    font-size: 0.19rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.15rem;
  }
  .about-main {
    display: none;
    box-sizing: border-box;
    padding: 0 0.15rem;
    padding-bottom: 0.49rem;
    width: 100%;
    font-size: 0.14rem;
    line-height: 0.24rem;
  }
  .about-friend {
    box-sizing: border-box;
    padding: 0 0.15rem;
    h4 {
      font-size: 0.19rem;
      line-height: 0.27rem;
      margin-top: 0.38rem;
      margin-bottom: 0.34rem;
    }
    li {
      box-sizing: border-box;
      width: 0.858rem;
      height: 0.858rem;
    }
  }
}
</style>
